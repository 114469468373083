import Layout from '@/components/Layout';
import {
  Head,
  Originator,
  EventDetails,
  EventSchedule
} from '@/sections/ArgoCon';
import ArgoCon2021 from '@/sections/ArgoCon/components/ArgoCon2021';
import { ArgoCon2023 } from '@/sections/ArgoCon/components/ArgoCon2023';
import { ArgoConEU2024 } from '@/sections/ArgoCon/components/ArgoConEU2024';
import { ArgoConNA2023 } from '@/sections/ArgoCon/components/ArgoConNA2023';
import { ArgoConNA2024 } from '@/sections/ArgoCon/components/ArgoConNA2024';
import { seoConfiguration } from '@/utils/seo-config';

import './ArgoCon.scss';

const ArgoCon = () => {
  const {
    title: titleSeo,
    description: descriptionSeo,
    image: imageSeo
  } = seoConfiguration.argocon;

  return (
    <Layout image={imageSeo} title={titleSeo} description={descriptionSeo}>
      <div className="position-relative">
        <div className="argocon-wrapper container-fluid content--max-width">
          <Head />
          <Originator />
          <ArgoConNA2024 />
        </div>  
        <div className="argocon-wrapper container-fluid content--max-width">
          <ArgoConEU2024 />
        </div>
        <div className="argocon-wrapper container-fluid content--max-width">
          <ArgoConNA2023 />
        </div>
        <div className="argocon-wrapper container-fluid content--max-width">
          <ArgoCon2023 />
        </div>
        <div className="argocon-wrapper container-fluid content--max-width">
          <EventDetails />
          <EventSchedule />
        </div>
        <div className="argocon-wrapper container-fluid content--max-width">
          <ArgoCon2021 />
        </div>
        <div className="gradient-background no-global-padding"></div>
      </div>
    </Layout>
  );
};

export default ArgoCon;
