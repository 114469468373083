import { EventDetailBlock } from '../EventDetails';
import { TalkListItem } from '../EventSchedule';
import Image from '@/components/Image';
import { Typography } from '@/components/Typography';
import ArgoconNA2024 from '@/static/assets/images/ArgoCon/argocon-na-2023.svg';

import './ArgoConNA2024.scss';

export const ArgoConNA2024 = () => {
  return (
    <>
      <Typography
        className="argocon-2021-header"
        component="h4"
        id="argocon-2021"
        typographyKind="header-secondary-large-bold"
        margin={[7, 0]}
      >
        ArgoCon NA 2024
      </Typography>
      <div className="argocon-2024-thumbnail">
        <Image imageUrl={ArgoconNA2024} alt="ArgoCon 2024 NA" />
      </div>
      <div
        className="justify-space-between mt-1 flex-wrap argoconna-2023-event-details"
        style={{ marginBottom: '48px' }}
      >
        <EventDetailBlock title="Date" description="November 12, 2024" />
        <EventDetailBlock title="Location" description="Salt Lake City, Utah" />
        <EventDetailBlock
          title="Website"
          url="https://events.linuxfoundation.org/kubecon-cloudnativecon-north-america/co-located-events/argocon/"
        />

        <EventDetailBlock
          title="Schedule"
          url="https://colocatedeventsna2024.sched.com/overview/type/ArgoCon"
        />
      </div>

      <Typography
        component="h4"
        typographyKind="header-secondary-large-bold"
        margin={[8, 0]}
      >
        Talks involving Akuity staff
      </Typography>
      <div className={`argocon-2022-day-1-talks`}>
        <ul>
        <TalkListItem
            title="Where User Experience and Open Source Thrives"
            authors="Christian Hernandez, Akuity and Jacob Feldman, CoreWeave"
            scheduleUrl="https://sched.co/1j1bs"
          />
          <TalkListItem
            title="Managing Application Dependencies in Argo CD"
            authors="Christian Hernandez, Akuity"
            scheduleUrl="https://sched.co/1izsc"
          />
          <TalkListItem
            title="Bridge the Gap Between Terraform and GitOps" 
            authors="Junze Bao & Alexander Matyushentsev, Akuity"
            scheduleUrl="https://sched.co/1iztb"
          />
        </ul>
      </div>
    </>
  );
};
